import React, { useState, useEffect } from 'react';
import './styles/HeroSection.css'; // Ensure you have this CSS file for styling
import backgroundImage1 from '../assets/Background1.jpeg';
import backgroundImage2 from '../assets/Background2.jpeg';
import backgroundImage3 from '../assets/Background3.jpeg';
import backgroundImage4 from '../assets/Background4.jpeg';
import { FaWhatsapp} from 'react-icons/fa';

function HeroSection({ scrollToContact })  {
  const images = [backgroundImage1, backgroundImage2, backgroundImage3, backgroundImage4];
  const headings = [
    {
      "h1": "Limpieza Profesional en Panamá",
      "h2": "Soluciones de Limpieza Residencial y Corporativa de Alto Nivel"
    },
    {
      "h1": "Pintura de Calidad en Panamá",
      "h2": "Servicios de Pintura General y Especializada para Todo Tipo de Espacios"
    },
    {
      "h1": "Especialistas en Alturas en Panamá",
      "h2": "Limpieza y Pintura de Fachadas, Estacionamientos y Más"
    },
    {
      "h1": "Mantenimiento Integral en Panamá",
      "h2": "Control de Plagas, Limpieza de Cocinas Industriales y Lavado de Muebles"
    }
  ];  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(currentIndex => (currentIndex + 1) % images.length);
    }, 7000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="hero-section">
      {images.map((image, index) => (
        <div
          key={index}
          className="background-image"
          style={{
            backgroundImage: `url(${image})`,
            opacity: index === currentImageIndex ? 1 : 0,
          }}
        />
      ))}
      <div className="hero-content">
        {headings.map((heading, index) => (
          <div
            key={index}
            className={`text-content ${index === currentImageIndex ? 'fade-in' : ''}`}
            style={{
              position: 'absolute',
              opacity: index === currentImageIndex ? 1 : 0,
            }}
          >
            <h1>{heading.h1}</h1>
            <h2>{heading.h2}</h2>
          </div>
        ))}
      <div className="hero-buttons">
      <a href="https://wa.me/50765799849" target="_blank" rel="noopener noreferrer">
  <button id="whatsapp-button" className="green-button">
    WhatsApp<FaWhatsapp />
  </button>
</a>
<a href="javascript:void(0);" onClick={scrollToContact}>
  <button id="contact-button" className="blue-button">
    Contactanos
  </button>
</a>


      </div>
  </div>

    </div>
  );
}

export default HeroSection;
