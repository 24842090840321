import React from 'react';
import './styles/ServiceCard.css'; // Ensure the CSS file is correctly referenced

function ServiceCard({ title, description, imageUrl }) {
  return (
    <div className="service-card">
      <img src={imageUrl} alt={title} className="service-image" />
      <h2 className="service-title">{title}</h2> {/* Added className */}
      <p className="service-description">{description}</p> {/* Added className */}
    </div>
  );
}

export default ServiceCard;
