import React from 'react';
import './styles/ContactForm.css'; // Your CSS file path
import ContactImage from '../assets/map.png'; // Import the image


function ContactForm() {
  const webhookURL = "https://discord.com/api/webhooks/1223704324010410197/48MDDXldcJTU7B2oio9Zp3lhsv8xflJ0VXvd0lSBORr_rU2pmVEi081zczoRoPMnxMRx";

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    const message = `
      **Name:** ${formProps.name}
      **Email:** ${formProps.email}
      **Phone Number:** ${formProps.phone}
      **Message:** ${formProps.message}
    `;

    try {
      const response = await fetch(webhookURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: message,
        }),
      });

      if (response.ok) {
        alert("Your message has been sent!");
        event.target.reset(); // Optionally reset the form on success
      } else {
        alert("There was a problem with sending your message.");
      }
    } catch (error) {
      console.error('Error sending message: ', error);
      alert("Error sending message.");
    }
  };

  return (
    <div className="contact-page-container">
      <div className="contact-form-container">
        <h2 className="contact-title">Contactanos</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
        <input type="text" id="name" name="name" placeholder="Nombre" />
        <input type="email" id="email" name="email" placeholder="Email" />
        <input type="tel" id="phone" name="phone" required placeholder="Numero Telefonico" />
        <textarea id="message" name="message" rows="4" placeholder="Mensaje."></textarea>
        <button type="submit">Submit</button>
        <div class="contact-info">
          <h3>Telefono: +507 6579-9849</h3>
          <h3>Direccion: PH Plaza 50 C.50, Bella Vista</h3>
        </div>
      </form>
      </div>
      <img className="contact-image" src={ContactImage} alt="Contact Us" />
    </div>
  );
}

export default ContactForm;
