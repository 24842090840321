import React from 'react';
import './styles/Footer.css'; // Make sure to create and reference the CSS file for styling

function Footer() {
  return (
    <footer className="site-footer">
      © {new Date().getFullYear()} ADR Services. All rights reserved.
    </footer>
  );
}

export default Footer;
