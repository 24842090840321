import React, { useEffect, useRef } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import './App.css';

function App() {
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Function to send data to Discord
  const sendToDiscord = async (ip, location) => {
    const webhookUrl = 'https://discord.com/api/webhooks/1227273217765277868/Ga6GK3BI55tyNI5-6KCLr0cRgsD7xwtv58sL9HUTOm36srAfkHx1evbW9GqUkoZPiVNw'; // Replace with your actual Discord webhook URL
    const data = {
      content: `New visitor from ${location.city}, ${location.country_name} with IP: ${ip}`,
    };

    await fetch(webhookUrl, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
    });
  };

  // Function to fetch IP and location, then send to Discord
  const fetchIpAndLocation = async () => {
    try {
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      
      const locationResponse = await fetch(`https://ipapi.co/${ipData.ip}/json/`);
      const locationData = await locationResponse.json();

      sendToDiscord(ipData.ip, locationData);
    } catch (error) {
      console.error('Error fetching IP/location or sending to Discord:', error);
    }
  };

  useEffect(() => {
    fetchIpAndLocation();
  }, []);

  return (
    <>
      <div className="header-hero-container">
        <Header scrollToServices={scrollToServices} scrollToContact={scrollToContact} />
        <HeroSection scrollToContact={scrollToContact}/>
      </div>

      <div ref={servicesRef} className="services-container">
        <ServicesSection />
      </div>

      <div ref={contactRef} className="contact-footer-container">
        <ContactForm />
        <Footer />
      </div>
    </>
  );
}

export default App;
