import React from 'react';
import ServiceCard from './ServiceCard';
import './styles/ServicesSection.css'; // Make sure this CSS file includes the styles for .title
import highAltitudeCleaningImage from '../assets/Alturas.png';
import generalPaintingImage from '../assets/Residencial(1).png';
import residentialCleaningImage from '../assets/Residencial.png';
import furnitureAndCarpetCleaningImage from '../assets/Residencial(2).png';
import pestControlImage from '../assets/bug.png';
import highAltitudePaintingImage from '../assets/Residencial(4).png';
import parkingLotPaintingImage from '../assets/Residencial(5).png';
import facadeMaintenanceImage from '../assets/Residencial(6).png';
import corporateCleaningImage from '../assets/Corpo.png';
import industrialKitchenCleaningImage from '../assets/Kitchen.png';
// Adjust your servicesData to use the imported image
const servicesData = [
  {
    id: 1,
    title: "Limpieza Residencial",
    description: "Limpieza profesional y meticulosa para hacer que su hogar esté impecable y cómodo.",
    imageUrl: residentialCleaningImage
  },
  {
    id: 2,
    title: "Limpieza de Gran Altura",
    description: "Limpieza exterior especializada para edificios de gran altura, asegurando seguridad y brillantez en cada nivel.",
    imageUrl: highAltitudeCleaningImage
  },
  {
    id: 3,
    title: "Limpieza Corporativa",
    description: "Servicios de limpieza personalizados para espacios corporativos para mantener un entorno de negocio impecable.",
    imageUrl: corporateCleaningImage
  },
  {
    id: 4,
    title: "Limpieza de Cocinas Industriales",
    description: "Limpieza profunda para cocinas industriales para cumplir con los estándares de salud y mejorar la experiencia culinaria.",
    imageUrl: industrialKitchenCleaningImage
  },
  {
    id: 5,
    title: "Limpieza de Muebles y Alfombras",
    description: "Limpieza experta para refrescar y revitalizar sus muebles y alfombras, eliminando suciedad y manchas de manera efectiva.",
    imageUrl: furnitureAndCarpetCleaningImage
  },
  {
    id: 6,
    title: "Pintura General",
    description: "Servicios de pintura de calidad para interiores y exteriores, proporcionando a su espacio un aspecto fresco y vibrante.",
    imageUrl: generalPaintingImage
  },
  {
    id: 7,
    title: "Pintura de Gran Altura",
    description: "Servicios de pintura de precisión en alturas, mejorando el atractivo estético de los exteriores de los edificios.",
    imageUrl: highAltitudePaintingImage
  },
  {
    id: 8,
    title: "Pintura de Estacionamientos",
    description: "Servicios de pintura y marcado de líneas duraderos para estacionamientos, asegurando un espacio ordenado y organizado para los vehículos.",
    imageUrl: parkingLotPaintingImage
  },
  {
    id: 9,
    title: "Control de Plagas",
    description: "Soluciones integrales de manejo de plagas para proteger las propiedades de infestaciones y mantener un ambiente libre de plagas.",
    imageUrl: pestControlImage
  },
  {
    id: 10,
    title: "Mantenimiento de Fachadas",
    description: "Servicios de mantenimiento y restauración para exteriores de edificios, preservando la integridad estructural y el atractivo visual.",
    imageUrl: facadeMaintenanceImage
  }
]



function ServicesSection() {
  return (
<div className="overflow-control">
  <div className="services-section">
    <h1 className="title">Servicios</h1>
    {servicesData.map((service) => (
      <ServiceCard
        key={service.id} // Assuming each service has a unique 'id'
        title={service.title}
        description={service.description}
        imageUrl={service.imageUrl}
      />
    ))}
  </div>
</div>
  );
}


export default ServicesSection;
