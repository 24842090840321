import React from 'react';
import { FaWhatsapp} from 'react-icons/fa';
import logo from '../assets/LogoADR.png';
import './styles/Header.css';

// Accept scroll functions via props
function Header({ scrollToServices, scrollToContact }) {
  return (
    <header className="header">
      <img src={logo} alt="Extermination Company Logo" className="logo" />
      
      <div className="header-controls">
        <div className="nav-buttons">
          <button onClick={scrollToServices}>Servicios</button>
          <button onClick={scrollToContact}>Contactanos</button>
        </div>
        <div className="social-icons">
          <a href="https://wa.me/50765799849 "target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
        </div>
      </div>
    </header>
  );
}

export default Header;
